@import '../../styles/global.scss';

.container {
    display: block;
    border-bottom: 1px solid $otcGreen;

    .text {
        font-size: 14px;
        font-weight: $fontWeightBold;
    }
}