@import "../../styles/global.scss";

.textLink {
  display: inline-block;
  cursor: pointer;
  color: $otcGreen;

  &:hover {
    text-decoration: underline;
  }
}