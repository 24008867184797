@import '../../../styles/global.scss';

.container {
  cursor: pointer;
  font-family: $otcFontPrimary;
  padding: 7px 9px;
  white-space: pre-wrap;
}

.settings {
  display: flex;
  align-items: center;
  background-color: $otcLightGray;
  font-family: $otcFontPrimary;
  font-weight: $fontWeightExtraBold;
  padding: 7px 9px;

  .icon {
    color: $otcBlack !important;
  }

  .settingsText {
    color: $otcBlack !important;
    margin-left: 4px;
  }
}