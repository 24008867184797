@import './global.scss';

// custom devexpress

$fieldBorderColor: #ddd;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otc-theme {
  .maxHeight {
    max-height: 34px;
  }

  .phone-select {
    &.hasCountryCode {
      .dx-texteditor-input {
        padding-left: 5px
      }
      
      .dx-selectbox.dx-textbox.dx-texteditor.dx-editor-outlined {
        border-left: 1px solid #ddd;

        &.dx-state-hover {
          @include borderGreen();
        }

        &.dx-state-active {
         border-color: $otcGreen;
        }
      }

      .dx-textbox.dx-texteditor.dx-editor-outlined {
        border-left: none;
      }
    }

    .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
      // border-right: none;
      border-left: 1px solid #ddd;
    }

    .dx-dropdowneditor-button.dx-button-mode-contained, .dx-dropdowneditor-button.dx-button-mode-outlined, .dx-dropdowneditor-button.dx-button-mode-text {
      background-color: #f5f5f5;
      width: 15px;
      min-width: 0;
      margin-left: -5px;
    }

    .dx-dropdowneditor-icon {
      width: auto;
    }
  }

  .small {
    .dx-checkbox {
      width: 100%;
    }

    .dx-checkbox-icon {
      width: 18px;
      height: 18px;
    }

    .dx-checkbox-has-text .dx-checkbox-text {
      vertical-align: middle;
      font-size: 13px;
      font-weight: $fontWeightRegular;
      line-height: 16px;
      padding-left: 30px;
    }    
  }

  .dx-checkbox-icon {
    border-color: $otcGray;
  }

  .dx-checkbox-text {
    @include h3;
    letter-spacing: -.05px;
  }

  .dx-checkbox-has-text .dx-checkbox-text {
    overflow-wrap: normal;
  }
  .dx-show-clear-button {
    .dx-texteditor-buttons-container {
      align-items: flex-start;
    }

    .dx-clear-button-area {
      height: 25px;
    }
  }

  .dx-icon-clear:before {
    content: "\f00a";
  }

  // DROP DOWN BUTTON

  .dx-buttongroup-wrapper {
    height: 30px;

    .dx-dropdownbutton-action.dx-button .dx-button-content {
      padding: 8px;
    }

    .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
      border-color: $otcGreen;
      border-radius: 4px;
    }

    .dx-button-text {
      font-size: 11px;
      color: $otcGreen;
    }

    .dx-button-has-text {
      .dx-icon {
        line-height: 16px;
      }

      .dx-icon.dx-icon-right {
        font-size: 25px;
        color: $otcGreen;
        margin: 0;
      }
    }
  }
  // TODO FIX SELECTED HIGHLIGHT FROM BLUE
  .dx-list {
    background-color: $otcWhite;
    border: 1px solid $otcLightGray;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: red;
    color: #fff;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected {
    background-color: $otcWhite;
  }

  .dx-radio-value-container {
    vertical-align: top;
  }
  
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    border-radius: 0;
    font-size: 16px;
  }

  .dx-dropdownbutton-action.dx-button .dx-button-content {
    padding: $smallSpace $mediumSpace;
    text-align: left;
  }

  .dx-button-has-text .dx-icon.dx-icon-right {
    font-size: 40px;
    float: right;
  }
  .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
    background-color: transparent;
    border-color: transparent;

    &:before {
      color: $otcGreen;
    }
  }

  .dx-tag-container.dx-texteditor-input-container .dx-texteditor-input {
    min-height: inherit;
  }

  .dx-tag-content {
    padding: 2px 20px 2px 5px;
    font-size: 11px;
    background-color: $otcGreen;
    color: $otcWhite;
  }

  .dx-tag-remove-button:after, .dx-tag-remove-button:before {
    right: 10px;
    margin-top: -6px;
    width: 2px;
    height: 10px;
    background: $otcWhite;
  }

  .dx-tag-remove-button:after {
    right: 6px;
    margin-top: -2px;
    width: 10px;
    height: 2px;
  }

  .dx-texteditor.dx-state-hover,
  .dx-texteditor.dx-state-hover.dx-editor-filled,

  .dx-texteditor.dx-state-active.dx-editor-filled,
  .dx-texteditor.dx-state-active.dx-editor-outlined,
  .dx-texteditor.dx-state-active.dx-editor-underlined,
  .dx-texteditor.dx-state-focused.dx-editor-filled,
  .dx-texteditor.dx-state-focused.dx-editor-outlined,
  .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: $otcGreen;
  }

  .dx-texteditor.dx-editor-outlined, .dx-texteditor-input {
    border-radius: 0;
  }

  .dx-show-clear-button .dx-icon-clear {
    color: $otcGreen;
  }

  .phone-container {
    display: flex;

    .dx-selectbox-container {
      background-color: $otcLightGray;
    }

    .dx-dropdowneditor-button {
      min-width: 0;
      width: auto;
      padding-right: 8px;
    }

    .dx-dropdowneditor-icon {
      width: 8px;
    }

    .dx-texteditor-input-container {
      font-size: 12px;
    }
    
    .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
      font-weight: $fontWeightSemiBold;
    }
  }

  .auth-form {
    .dx-texteditor-input {
      font-size: 14px;;
    }

    .dx-checkbox {
      display: block;
    }

    .dx-checkbox-has-text .dx-checkbox-text {
      font-size: 12px;
    }
  }

  .dx-texteditor.dx-state-readonly{
    border-style: solid;
  }

  .dx-scrollable-native.dx-scrollable-vertical, .dx-scrollable-native.dx-scrollable-vertical .dx-scrollable-container {
    overflow-y: overlay;
  }

  // ERRORS
  .dx-invalid.dx-texteditor {
    border: 1px solid $fieldBorderColor;
    @include notValid;
  }

  .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container:after {
    content: initial;
  }

  .dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused,
  .dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused,
  .dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-focused {
    border-color: $otcGreen;
    border-bottom-color: $otcErrorColor;
  }
  
  .dx-button-mode-contained.dx-button-default,
  .dx-button-mode-contained.dx-button-default.dx-state-hover,
  .dx-button-mode-contained.dx-button-default.dx-state-active {
    background: none;
  }

  // phone input
  .react-tel-input .form-control {
    border: 1px solid $fieldBorderColor;
    border-radius: 0;
    width: 100%;

    &[disabled] {
      border: 1px solid $otcLightGray;
      color: $otcLightGray;
    }
  }

  .react-tel-input .flag-dropdown {
    border-color: $fieldBorderColor;
  }

  .dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
    padding: 0px;
  }

}

.dx-calendar-views-wrapper {
  .dx-calendar-cell.dx-calendar-empty-cell {
    background: none;
  }
}
.dx-datagrid-headers {
  background: #ebf2ff !important;
  color: $otcBlack !important;

  .dx-header-row > td {
    &.legend {
      background-color: $otcGreen !important;
    }
  }
}

.dx-datagrid .dx-row > td {
  &.legend {
    background-color: #ebf2ff !important;
    border-color: #ddd;
    padding-right: 10px;
  }

  &.legend[role=columnheader] {
    background-color: $otcGreen !important;
  }
}

.dx-popup-title  {
  border-bottom: none !important;
}
.dx-toolbar-items-container{
  border-bottom: 1px solid $otcGreen !important;

}
.dx-toolbar-label .dx-toolbar-item-content > div {
  color: $otcGreen;
  font-family: $otcFontPrimary;
  font-size: 14px;  
  font-weight: 700;
  overflow: unset !important;

}
.no-sort-grid {
  .dx-sort {
    display: none !important;
  }
}

.dx-state-hover > td > a, .dx-state-hover a:hover, .dx-selection a {
  color: $otcWhite
}

.dx-list-select-all-checkbox {
  margin: 2px 0 -3px 10px !important;
}
.dx-tagbox-popup-wrapper .dx-list-select-all {
  padding-bottom: 5px !important;
}

.dx-list-select-all-label {
  font-size: 12px;
  line-height: 12px;
}

// .dx-list-item-after-bag, .dx-list-item-before-bag {
//   vertical-align: baseline !important;
// }

.dx-checkbox-checked .dx-checkbox-icon:before {
  font-size: 12px !important;
  top: -10% !important;
}

// DROP DOWN
.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding: 0 !important;
}

.dx-item-content.dx-list-item-content {
  white-space: pre-wrap;
}