@import '../../styles/global.scss';

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $otcGreen;
    font-weight: $fontWeightBold;
    border-bottom: 1px solid $otcGreen;
    padding-bottom: 8px;

    .left {
        display: flex;
        align-items: center;

        .backBtn {
            margin-right: 6px;
            cursor: pointer;
        }
    }

    .closeBtn {
        cursor: pointer;
    }
}

.dataContainer {
    overflow: scroll;

    &.hide {
        display: none;
    }
}