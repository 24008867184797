@import "../../styles/global.scss";

@mixin disabledPrimary {
  background-color: $otcLightGray;
  border-color: $otcLightGray;
  color: $otcWhite;
  cursor: default;
}

@mixin disabledSecondary {
  background-color: transparent;
  border-color: $otcLightGray;
  color: $otcLightGray;
  cursor: default;
}

.button {
  display: inline-block;

  &.fullWidth {
    width: 100%;

    button {
      width: 100%;
    }
  }

  button {
    border-width: 2px;
    border-style: solid;
    font-weight: bold;
    min-width: 120px;
    height: 40px;
    text-transform: capitalize;
    padding: 0 25px;
    cursor: pointer;
    font-size: .9375rem;
    outline: none;
    transition: background-color 0.2s ease-in-out;
    display: inline-block;
    text-decoration: none !important;
    border-radius: 4px;
    letter-spacing: .035em;
    font-family: $otcFontPrimary;

    &.small {
      padding: 0 15px;
      font-size: .8rem;
    }

    &.extraSmall {
      height: 30px;
      min-width: 40px;
      padding: 0 10px;
      font-size: 11px;
    }

    &.green {
      border-color: $otcGreen;

      &.primary {
        color: $otcWhite;
        background: $otcGreen;
  
        &:hover {
          color: $otcGreen;
          background: transparent;
        }

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcGreen;
        background: transparent;
        
        &:hover {
          background-color: transparent;
          background-color: $otcGreen;
          color: $otcWhite;
        }

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.pink {
      border-color: $otcPink;

      &.primary {
        color: $otcWhite;
        background: $otcPink;
  
        &:hover {
          color: $otcPink;
          background: transparent;
        }

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcPink;
        background: transparent;
        
        &:hover {
          background-color: transparent;
          background-color: $otcPink;
          color: $otcWhite;
        }

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.orange {
      border-color: $otcOrange;

      &.primary {
        color: $otcWhite;
        background: $otcOrange;
  
        &:hover {
          color: $otcOrange;
          background: transparent;
        }

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcOrange;
        background: transparent;
        
        &:hover {
          background-color: transparent;
          background-color: $otcOrange;
          color: $otcWhite;
        }

        &:disabled {
          @include disabledSecondary();
        }
      }
    }
    
    &.gray {
      border-color: $otcGray;

      &.primary {
        color: $otcWhite;
        background: $otcGray;
  
        &:hover {
          color: $otcGray;
          background: transparent;
        }

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcGray;
        background: transparent;
        
        &:hover {
          background-color: transparent;
          background-color: $otcGray;
          color: $otcWhite;
        }

        &:disabled {
          @include disabledSecondary();
        }
      }
    }

    &.red {
      border-color: $otcErrorColor;

      &.primary {
        color: $otcWhite;
        background: $otcErrorColor;
  
        &:hover {
          color: $otcErrorColor;
          background: transparent;
        }

        &:disabled {
          @include disabledPrimary();
        }
      }

      &.secondary {
        color: $otcErrorColor;
        background: transparent;
        
        &:hover {
          background-color: transparent;
          background-color: $otcErrorColor;
          color: $otcWhite;
        }

        &:disabled {
          @include disabledSecondary();
        }
      }
    }
  }
}
