@import '../../styles/global.scss';

.label {
  display: block;
  font-family: $otcFontPrimary, sans-serif;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 12px;
  cursor: initial;

  &.inline {
    display: inline-block;
  }
}