@import '../../styles/global.scss';

.container {
  position: relative;
  display: block;
  font-size: 12px;
  border: 1px solid #e2e2e2;
  background-color: $otcWhite;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;

  .formContainer {
    position: relative;
  }

  .form {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 25px;
    width: 100%;
    
    .field {
      max-width: 16vw;
    }

    .fieldColumns {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px 5px;
    }

    .dateFields {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5px;
      font-size: 12px;
    }
  }

  .error {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700;
    color: $otcErrorColor;
  }

  .warning {
    margin-top: 10px;
    font-size: 13px;
    font-style: italic;    
    color: $otcGreen;
    font-weight: 700;
  }

  .toggle {
    position: absolute;
    color: $otcGreen;
    bottom: 0;
    right: 0;
  }
}

.controls {
  display: flex;
  align-items: center;
  margin-top: 20px;

  > div {
    margin-right: 10px;
  }

  .dropdownBtn {
    width: 100px;
  }
}

.labelButton {
  cursor: pointer;

  &:hover {
    color: $otcGreen;
  }
}

.collapseOption {
  display: table;
  margin-top: 5px;
}

.collapsedContainer {
  .field {
    font-size: 11px;
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
}

.error {
  font-style: italic;
  color: $otcErrorColor;
}