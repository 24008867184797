// style-guide colors
$otcBackground: #fff;
$otcWhite: #fff;

$otcGreen: #00a88f;
$otcGreenSecondary: #32bbab;
$otcOrange: #f7931d;
$otcOrangeSecondary: #fab061;
$otcPink: #ec098c;
$otcPinkSecondary: #f072ab;

$otcBlack: #292929;
$otcDarkGray: #444444;
$otcGray: #939597;
$otcLightGray: #e2e2e2;
$otcDarkGrey: $otcDarkGray;
$otcGrey: $otcGray;
$otcLightGrey: $otcLightGray;

$otcSuccessColor: $otcGreen;
$otcWarningColor: $otcOrange;
$otcErrorColor: #ed1c24;
$otcWarningColor: #cd5a68;

// max height for animations
$maxHeightFull: 1500px;

// style-guide fonts
$otcFontPrimary: 'Nunito Sans', 'Helvetica', 'Arial', sans-serif;
$otcFontSecondary: 'Source Serif Pro', 'Georgia', serif;

$fontWeightLight: 200;
$fontWeightRegular: 400;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 900;

$smallSpace: 8px;
$mediumSpace: 16px;

// sidebar dimensions
$sidebarWidth: 300px;
$sidebarMargin: 100px;

* {
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  width: 100%;
  font-family: $otcFontPrimary, sans-serif;
  // ensures advertising iframes don't add space below footer
  > iframe {
    position: absolute;
    height: 0;
    width: 0;
  }
}

html {
  // base font size for rem
  font-size: 16px;
  // adjust base font for very small screens
  @media screen and (max-width: 340px) {
    font-size: 13px;
  }
}

@mixin btnHover {
  box-shadow: 0 0 4px 4px #E7E7E7;
}

@mixin bgGreen($alpha: .4) {
  background-color: rgba(0, 168, 143,$alpha);
}

@mixin borderGreen($alpha: .4) {
  border-color: rgba(0, 168, 143, $alpha);
}

$lineHeight: 24px;

// spacing
$xsmallSpace: 4px;
$smallSpace: 8px;
$mediumSpace: 16px;
$largeSpace: 24px;
$XLargeSpace: 32px;
$XXLargeSpace: 64px;

// global hovers

@mixin green-link {
  &:hover {
    color: $otcGreen !important;
  }
}

@mixin orange-link {
  &:hover {
    color: $otcOrange  !important;
  }
}

@mixin pink-link {
  &:hover {
    color: $otcPink !important;
  }
}

@mixin gray-link {
  &:hover {
    color: $otcGray !important;
  }
}

@mixin black-link {
  &:hover {
    color: #000 !important;
  }
}

// style-guide typography helpers
@mixin h1 {
  @include lg {
    font-size: 3em;
    margin-bottom: 70px;
  }

  @include md {
    font-size: 2.625em;
    margin-bottom: 50px;
  }

  @include sm {
    font-size: 1.875em;
    margin-bottom: 25px;
  }
}

@mixin h2 {
  font-size: 2.25em;
}

@mixin h3 {
  // text-transform: uppercase;
  @include lg {
    font-size: 1.25em;
  }
  @include md {
    font-size: 1.25em;
  }
  @include sm {
    font-size: 1.125em;
  }
}

@mixin caption {
  font-size: .75em;
  color: $otcGray;
  display: inline-block;
}

@mixin link {
  cursor: pointer;
  color: $otcDarkGray;
  text-decoration: underline;
  transition: 0 all;
  &:hover {
    color: $otcGreen;
  }
}

@mixin button {
  border: 2px solid $otcGreen;
  font-weight: bold;
  color: $otcGreen;
  background: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: .6em 1.2em;
  cursor: pointer;
  font-size: .9375rem;
  outline: none;
  transition: background-color 0.2s ease-in-out;
  display: inline-block;
  text-decoration: none !important;
  border-radius: 0 !important;
  letter-spacing: .035em;

  &:hover {
    background-color: $otcGreen;
    color: $otcWhite;
  }

  &:disabled {
    background-color: transparent;
    border-color: $otcLightGray;
    color: $otcLightGray;
    cursor: default;

    &:hover {
      background-color: transparent;
      border-color: $otcLightGray;
      color: $otcLightGray;
    }
  }
}

@mixin sideColumn {
  @include lg {
    width: 300px;
  }
}

// other style-guide helpers
$dividerGray: solid 1px $otcLightGray;
$dividerGrey: $dividerGray;
$dividerGreen: solid 1px $otcGreen;
$dividerThick: solid 5px $otcGreen;
$dividerMedium: solid 3px $otcGreen;

// style-guide breakpoints and media query helpers
$bp-tablet: 768px;
$bp-desktop: 1024px;
$max-width: 1280px;

@mixin sm {
  @media screen and (max-width: #{$bp-tablet - 1px}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$bp-tablet}) and (max-width: #{$bp-desktop - 1px}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$bp-desktop}) {
    @content;
  }
}

@mixin sm-md {
  @media screen and (max-width: #{$bp-desktop - 1px}) {
    @content;
  }
}

@mixin md-lg {
  @media screen and (min-width: #{$bp-tablet}) {
    @content;
  }
}

// default link
a {
  @include link;
}


// smooth out placeholder inconsistencies
// (autoprefixer isn't adding the ms prefix which is needed for IE)
@mixin placeholder {
  &::placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
}

// Hide proprietary IE junk
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}

@mixin notValid {
  border-bottom-color: $otcErrorColor;
  border-bottom-width: 3px;
}

.mtNone {
  margin-top: 0;
}

.mtSm {
  margin-top: $smallSpace;
}

.mtMed {
  margin-top: $mediumSpace;
}

.mtLg {
  margin-top: $largeSpace;
}

.mtXL {
  margin-top: $XLargeSpace;
}

.mtXXL {
  margin-top: $XXLargeSpace;
}

.mbNone {
  margin-bottom: 0;
}

.mbSm {
  margin-bottom: $smallSpace;
}

.mbMed {
  margin-bottom: $mediumSpace;
}

.mbLg {
  margin-bottom: $largeSpace;
}

.mbXL {
  margin-bottom: $XLargeSpace;
}

.mbXXL {
  margin-bottom: $XXLargeSpace;
}