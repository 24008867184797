@import '../../styles/global.scss';

.headerLegend {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $otcGreen;
  color: $otcWhite;

  .divider {
    margin-left: 3px;
    margin-right: 5px;
  }
}

.sideHeader {
  font-weight: $fontWeightBold;
  text-align: right;
  background: #ebf2ff;
  color: $otcBlack;
}

.execPriceArrow {
  margin-left: 3px;
}