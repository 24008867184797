@import '../../styles/global.scss';

.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $largeSpace;
}

.header {
    color: $otcGreen;
    font-size: 20px;
    font-weight: $fontWeightBold;
    text-align: center;
}