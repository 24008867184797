@import "../../styles/global.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

  .title {
    font-size: 24px;
    margin: 0;
  }
  
  .mmName {
    display: inline-block;
    font-size: 24px;
    font-weight: normal;
    vertical-align: middle;
  }
  
  .mmLogo {
    display: inline-block;
    width: 40px;
    vertical-align: middle;
    margin-left: 8px;
    border: 1px solid $otcLightGray;
  }
}