@import '../../styles/global.scss';

.tabContainer {
    position: relative;
    border-bottom: 1px solid $otcGreen;
    padding-bottom: 9px;

    ul.tabs {
        display: flex;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-right: $XLargeSpace;
            font-size: 14px;
            
            a {
                text-decoration: none;
                padding-bottom: 4px;

                &.active {
                    font-weight: $fontWeightBold;
                    border-bottom: 4px solid $otcGreen;
                }
            }
        }
    }

    .download {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 22px;
        cursor: pointer;
        
        &.inactive {
            cursor: not-allowed;
        }

        &.isDownloading {
            cursor: progress;
        }
    }
}

.subMenu {
    display: flex;
    flex-wrap: wrap;
    background-color: #F5F5F5;
    padding: 0 8px;

    .menuItem {
        cursor: pointer;
        margin-right: 10px;
        padding: 4px 0;
        font-size: 14px;

        &.active {
            color: $otcGreen;
        }
        &:hover {
            color: $otcGreen;
            text-decoration: underline;
        }

        &:last-child {
            margin: 0;
        }
    }
}